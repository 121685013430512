import React, { useState } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios';
import Loader from './Loader';


const ContactForm = () => {
  const [isLoading,setIsLoading] = useState(false);
  const initialFormData = {
    fullName: '',
    email: '',
    phone: '',
    content: ''
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    axios.post('/api/contact/send-mail', formData)
      .then(response => {
        
        console.log('Data sent successfully:', response.data);
      })
      .catch(error => {
        toast.error('בעיה בשליחת המייל.')
        console.error('Error sending data:', error);
      }).finally(() => {
        setIsLoading(false)
        toast.success('נשלח בהצלחה!');
        setFormData(initialFormData)
      })
  };

  return (
    <Container className="mt-5">
      <ToastContainer rtl={true} />
      <Row className="justify-content-md-center form-container">
        <Col md={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formFullName">
              <Form.Label>שם מלא</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                placeholder="נא הכנס שם מלא"
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>כתובת מייל</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="נא הכנס כתובת מייל"
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>מספר טלפון</Form.Label>
              <Form.Control
                type="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="נא הכנס מספר טלפון"
                required
              />
            </Form.Group>

            <Form.Group controlId="formContent">
              <Form.Label>הודעה</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="content"
                value={formData.content}
                onChange={handleChange}
                placeholder="נא השאר כאן את הודעתך"
                required
              />
            </Form.Group>
            {isLoading? <Loader /> : (

            <div className='button-container'>
              <button className='submit-button-form btn' type="submit">
                שלח
              </button>

            </div>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;
