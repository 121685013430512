import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Carousel, Container } from 'react-bootstrap';

const ImageCarousel = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get('/api/upload/files'); // Adjust the URL as needed
                setImages(response.data);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, []); // Empty dependency array

    return (
        <Container className='image-carousel-container'>
            <div className='carousel-header-wrapper'>
                <h2 className='carousel-header-content'>
                    ההטבות החדשות ביותר
                </h2>
            </div>
        <Carousel className='my-4 d-flex justify-content-center carousel-images'>
            {images.length > 0 && images.slice(-3).map((image, index) => (
                <Carousel.Item key={index}>
                    <center>
                    <img
                        className="w-50 "
                        src={`/uploads/${image}`}
                        alt={`Slide ${index}`}
                    />
                    </center>
                </Carousel.Item>
            ))}
        </Carousel>
        </Container>
    );
};

export default ImageCarousel;
