import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';

const Header = () => {
  const [navbarOpacity, setNavbarOpacity] = useState(0.8);
  const [user, setUser] = useState({});

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const newOpacity = Math.min(1, 0.8 + scrollTop / 300);
    setNavbarOpacity(newOpacity);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <Navbar className='rounded-1 ps-4'
        expand="lg"
        fixed='top'
        style={{ opacity: navbarOpacity, transition: 'opacity 0.3s', backgroundColor: '#0d2c4b' }}
      >
        <Navbar.Brand href="#home">
          <img
            src="/images/logo-header.png"
            width="50"
            className="d-inline-block align-top mt-1 mx-1"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ backgroundColor: 'white' }} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link style={{ color: "white" }} href="/">מסך ראשי</Nav.Link>
            <Nav.Link style={{ color: "white" }} href="/gallery">רשימת הטבות</Nav.Link>
            {user.isAdmin && (
              <Nav.Link style={{ color: "white" }} href="/contact">צור קשר</Nav.Link>
            )}
            <Dropdown className='fw-bold' dir='rtl'>
              <Dropdown.Toggle variant="link" style={{ paddingTop: '7px', color: "white", textDecoration: 'none' }}>
                מילוי שאלונים
              </Dropdown.Toggle>
              <Dropdown.Menu dir='rtl' className='text-end ' style={{ minWidth: '10px' }}>
                <Dropdown.Item href="/questionnaires/insurance-agents">
                  - שאלון לסוכני ביטוח (מנהלי פמילי אופיס)
                </Dropdown.Item>
                <Dropdown.Item href="/questionnaires/family-office">- שאלון ליועצים ,מלווים ומתכננים פיננסים</Dropdown.Item>
                <Dropdown.Item href="/questionnaires/references">- שאלון מנהלי פמילי אופיס - הפניות</Dropdown.Item>
              </Dropdown.Menu>

            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
