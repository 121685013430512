import React from 'react'
import {RingLoader} from 'react-spinners'

const Loader = () => {
  return (
    <div className='d-flex justify-content-center my-2'>
        <RingLoader color='var(--text-color-form)' size={80} loading={true}/>
    </div>
  )
}

export default Loader