import React from 'react'

const MainScreenTable = () => {
  return (
    <div className='main-table-container'>
    <table>
      <thead>
        <tr>
          <th>שם המוצר</th>
          <th>מחיר לסוכן ביטוח</th>
          <th>מחיר מסובסד לחבר באיגוד</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>sms</td>
          <td>350 ש״ח</td>
          <td>275 ש״ח</td>
        </tr>
        <tr>
          <td>surense</td>
          <td>350 ש״ח</td>
          <td>275 ש״ח</td>
        </tr>
        <tr>
          <td>כנס באילת</td>
          <td>3500 ש״ח</td>
          <td>2000 ש״ח</td>
        </tr>
        <tr>
          <td colspan="3">
            אחריות מקצועית סבסוד לחבר באיגוד 500 ש״ח
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  )
}

export default MainScreenTable