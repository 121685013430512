import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Loader from '../../components/Loader'

const InsuranceAgentsQuestionnaire = () => {
  return (
    <>
    <Header />
    <div className='questionnaire'>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdmQKXvaTKEYLP2udBdvrzkJmnbw1hfOLMSgJNC6f2d_vSEtQ/viewform?embedded=true" title='insurance-agents' width="640" height="2318" frameborder="0" marginheight="0" marginwidth="0"><Loader /></iframe>
    </div>
    <Footer />
    </>
  )
}

export default InsuranceAgentsQuestionnaire