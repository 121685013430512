import React from 'react'
import ContactForm from '../components/ContactForm'
import Header from '../components/Header'
import Footer from '../components/Footer'

const ContactScreen = () => {
  return (
    <div>
        <Header />
        <div style={{paddingTop:'100px'}}>
        <ContactForm />
        </div>
        <Footer />
    </div>
  )
}

export default ContactScreen