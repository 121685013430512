import React from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Loader from '../../components/Loader'

const ReferenceQuestionnaire = () => {
  return (
    <>
    <Header />
    <div className='questionnaire'>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfJUfl5LpNGdco2OT_41Mdip4h6fP9QWEN38lGHo7X-KAZADQ/viewform?embedded=true" title='references' width="640" height="1000" frameborder="0" marginheight="0" marginwidth="0"><Loader /></iframe>
    </div>
    <Footer />
    </>
  )
}

export default ReferenceQuestionnaire