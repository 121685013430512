import React from "react";
import Header from "../components/Header";
import ImageGallery from "../components/ImageGallery";
import ImageCarousel from "../components/ImageCarousel";
import Footer from "../components/Footer";

const GalleryScreen = () => {

  
    return (
      <div>
        <Header className="mb-5" />
        <div style={{paddingTop:'100px'}}> 
        <ImageGallery />
        <ImageCarousel />
        <Footer />

        </div>
      </div>
    );
  };


export default GalleryScreen