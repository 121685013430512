import React from 'react'

const Footer = () => {
    return (
        <div className='mt-4 d-flex justify-content-center'>

            <div
                className=" rounded-1"
                style={{ backgroundColor: 'var(--background-color-low-opacity)',width:'30%' }}
            >
                <center>
                <p style={{ color: 'white', direction: 'ltr', margin: 0,fontSize:'12px' }}>
                    &copy; Osher Cohen - oshrico2@gmail.com
                </p>

                </center>
            </div>
        </div>
        );
};

export default Footer