import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Loader from '../../components/Loader'

const FamilyOfficeQuestionnaire = () => {
  return (
    <>
    <Header />
    <div className='questionnaire'>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScE4KnL8VtHE5DPN-7RywtjsNS8gSCd3X1RtJYBC2NN6pJ_Fw/viewform?embedded=true" title='family-office' width="640" height="2332" frameborder="0" marginheight="0" marginwidth="0"><Loader /></iframe>    </div>
    <Footer />
    </>
  )
}

export default FamilyOfficeQuestionnaire