import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import Loader from './Loader';


const ImageGallery = () => {
  const [images, setImages] = useState([]);
  const [isLoading,setIsLoading] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null);


  

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setIsLoading(true)
        const response = await axios.get('/api/upload/files'); // Adjust the URL as needed
        setImages(response.data);
      } catch (error) {
        console.error('Error fetching images:', error);
      } finally {
        setIsLoading(false)
      }
    };

    fetchImages();
  }, []);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };


  const handleCloseModal = () => {
    setSelectedImage(null);
  };



  return (
    <Container className="image-gallery mt-4">
      <Row className=''>
        {isLoading ? <Loader /> : (
        images.map((image, index) => (
          <Col key={index} xl={4} lg={6} className="mb-4 d-flex justify-content-center">
            <div className="gallery-image-wrapper " style={{ position: 'relative' }}>
              <img
                src={`/uploads/${image}`}
                alt={image}
                className="img-fluid"
                onClick={() => handleImageClick(`/uploads/${image}`)}
                style={{ cursor: 'pointer', transition: 'transform 0.3s ease, filter 0.3s ease' }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.1)';
                  e.currentTarget.style.filter = 'brightness(1.2)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.filter = 'brightness(1)';
                }}
              />
            </div>
          </Col>
        ))
        )}
      </Row>

      <Modal
        show={selectedImage !== null}
        onHide={handleCloseModal}
        centered
        className="custom-modal"
      >
        <Modal.Body className="p-0">
          <img src={selectedImage} alt="Full Size" style={{ width: '100%' }} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ImageGallery;
