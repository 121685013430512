import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainScreen from './screens/MainScreen';
import GalleryScreen from './screens/GalleryScreen';
import ContactScreen from './screens/ContactScreen';
import FamilyOfficeQuestionnaire from './screens/questionnaires/FamilyOfficeQuestionnaire'
import InsuranceAgentsQuestionnaire from './screens/questionnaires/InsuranceAgentsQuestionnaire'
import ReferenceQuestionnaire from './screens/questionnaires/ReferenceQuestionnaire'

const App = () => {

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<MainScreen />}
        />
        <Route
          path="/gallery"
          element={<GalleryScreen />}
        />
         <Route
          path="/contact"
          element={<ContactScreen />}
        />
        <Route
          path="/questionnaires/family-office"
          element={<FamilyOfficeQuestionnaire />
          }
        />
        <Route
          path="/questionnaires/insurance-agents"
          element={<InsuranceAgentsQuestionnaire />}
        /><Route
        path="/questionnaires/references"
        element={<ReferenceQuestionnaire />}
      />
      </Routes>
    </Router>
  );
};

export default App;
