import React from 'react'

const HorizontalRule = () => {
  return (
    <div className='px-3'>
        <hr className='border-3' style={{borderColor:'var(--text-color)'}}/>

    </div>
  )
}

export default HorizontalRule