import React from 'react'
import Header from '../components/Header'
import { Col, Container, Row } from 'react-bootstrap'
import MainScreenTable from '../components/MainScreenTable'
import '../main-screen.css'
import Footer from '../components/Footer'
import HorizontalRule from '../components/HorizontalRule'

const MainScreen = () => {
  document.querySelector('body').style.background = 'none';

  return (
    <div style={{ paddingTop: '60px' }} >


      <Container className='main-screen p-0' fluid>
        <Header />
        <Row style={{ backgroundColor: 'white' }}>
          <Col lg={3} md={4} className='p-0'>
            <img className='main-image1' width={'100%'} height={'90%'} src="/images/main-screen-image1.png" alt="" />
          </Col>
          <Col lg={9} md={8}>
            <div style={{ textAlign: 'center', paddingLeft: '50px' }}>
              <img className='main-image2' width={'40%'} src="/images/logo-ifo.png" alt="logo-ifo" />


            </div>
              <HorizontalRule />
            <p className='open-text me-3'>
              איגוד מנהלי הפמילי אופיס בישראל הוא גוף מקצועי ואתי המאגד את מנהלי הפמילי אופיס בפריסה ארצית.<br /> מטרת העל של האיגוד הינה הנגשת שרותי הפמילי אופיס בכל בית בישראל.<br /> כמו כן האיגוד שם לעצמו מטרה למסד את פעילות הפמילי אופיס ולהפוך לגוף בעל יכולות מקצועיות והשפעה בדרגים הרגולטורים השונים, <br />כל זאת לביסוס מעמדם של חברי האיגוד.<br /> חברי האיגוד יקבלו הדרכה מקיפה מטובי המקצוענים בענף בהיקף של 10 שעות מקצועיות.<br /> בסיום ההכשרה המקצועית יוענקו תעודות ״ מנהל פמילי אופיס חבר באיגוד ״ חברי האיגוד יהיו זכאים להטבות הבאות:
            </p>
            <Row className='align-items-center my-3 contact-buttons-container' >
              <Col md={4} xs={5}>
                <a className='btn submit-button-form' href="/contact">צרו קשר עכשיו</a>
              </Col>
              <Col className='d-flex justify-content-center' md={1} xs={1}>
                <h4>או</h4>
              </Col>
              <Col md={4} xs={5}>
                <a className='btn submit-button-whatsapp px-0' href="https://api.whatsapp.com/send/?phone=%2B972528788214&text&app_absent=0"> שלחו הודעת ווצאפ</a>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className='p-0'>
            <MainScreenTable />
          </Col>
        </Row>
        <div style={{ backgroundColor: 'white' }} className=' w-100  m-0 '>

          <Row style={{ backgroundColor: 'white' }} >
            <Col sm={7}>
              <div className='list-main-screen me-1'>
                <ul className='custom-bullets'>
                  <li>נסיעה מקצועית לחו”ל במחיר מיוחד</li>
                  <li>כנסים נבחרים</li>
                  <li>קורסים/הדרכות</li>
                  <li>הכשרה במכללת האיגוד</li>
                  <li>כװרת מומחים</li>
                  <li>יעוץ וגיבױ משפטי - ג’ון גבע</li>
                  <li>יעוץ תדמית</li>
                  <li>ליװי בשיװק דיגיטלי</li>
                  <li>כרטיסי ביקור של האיגוד/כרטיס ביקור דיגיטלי</li>
                </ul>
              </div>
            </Col>
            <Col sm={5}>
              <img className='main-image3' width={'70%'} src="/images/main-screen-image2.png" alt="" />
            </Col>
          </Row>
        </div>
        <Row className='main-screen-footer'>
          <Col>
            <p>
              לשאלות נוספות מוזמנים לפנות אל רחלי לנקרי<br/>052-878-8214 <br /> או אל זהבה לשד מנכ”לית האיגוד<br />054-488-2658
            </p>
          </Col>
      <Footer />
        </Row>
      </Container>


    </div>
  )
}

export default MainScreen
